import React from "react";
import s from "./styles.module.css";

export function Channel_info({ currentGroup }) {
  // Check if currentGroup is defined and has the expected properties
  if (!currentGroup) {
    return <div>No group selected</div>; // Fallback UI
  }

  // Destructure properties from currentGroup
  const { imageUrl, groupName, members } = currentGroup;

  // Ensure members array is defined and has at least one element
  const memberCount = members && members[0] ? members[0] : "Unknown";

  return (
    <div className={s.countain}>

      <h3>Channel Info</h3>{" "}
      <div className={s.txt}>
     
        <img
          src={imageUrl }
          alt={groupName }
          className={s.groupImage}
        />
        <div className={s.txtMembers}>
      
          <h4>{groupName || "Default Group Name"}</h4>
          <p className={s.p}>{currentGroup.members[0]<= 1? currentGroup.members[0] + " member." :currentGroup.members[0] + " members."  }</p>
        </div>
      </div>
    </div>
  );
}
