import React, { useState } from 'react';
import s from "./styles.module.css";
import groups from "../../../groups.json";

export function Navigation({ currentGroup, onClick }) {
  const [inputValue, setInputValue] = useState('');
  const words = ["woof", "waaf", "arf"];
  
  const groupToken = groups[0];
  const groupInfos = groups[1];

  function handleClick() {
    console.log(currentGroup);
  }

  function handleInputChange(event) {
    const value = event.target.value;
    const randomWord = words[Math.floor(Math.random() * words.length)];
    setInputValue(value + ' ' + randomWord);
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      setInputValue('Doge to the moon 🚀');
    }
  }

  return (
    <div className={`${s.contain} col-12`}>
      <div className={s.up}>
        <img src="/assets/img/pdp.jpg" alt="logo" className={`${s.logo}`} />
        <div className={`${s.inputZone} d-none d-xl-flex`}>
          <span className={`${s.icon} material-symbols-outlined`}>search</span>
          <input 
            className={`${s.input}`} 
            type="text" 
            value={inputValue} 
            onChange={handleInputChange} 
            onKeyPress={handleKeyPress} 
          />
        </div>
      </div>
      <div className={`${s.navZone} d-none d-xxl-flex`}>
        <button className={`${s.tokenomicsBtn} ${s.btnNav}`} onClick={() => onClick(groupToken)}>TOKENOMICS</button>
        <button className={`${s.infosBtn} ${s.btnNav}`} onClick={() => onClick(groupInfos)}>INFOS</button>
      </div>
    </div>
  );
}