import React from 'react';
import s from './styles.module.css'; // Assurez-vous que le chemin est correct
import { Name_groupe } from './components_part_mid/name_group/name_groupe';
import { Chat } from './components_part_mid/chat/chat';

export function Part_mid({ currentGroup }) {
  console.log('Part_mid currentGroup:', currentGroup);

  if (!currentGroup) {
    return <div>No group selected</div>;
  }

  return (
    <div className={`${s.contain} col-12 col-xl-8`}>
      
      <Name_groupe currentGroup={currentGroup} />
      <Chat currentGroup={currentGroup} />
    </div>
  );
}





// import React from 'react';

// export function Part_mid({ currentGroup }) {
//   console.log('Part_mid currentGroup:', currentGroup);

//   if (!currentGroup) {
//     return <div>No group selected</div>;
//   }

//   return (
//     <div>
//       <h1>{currentGroup.groupName}</h1>
//       {/* Render other details */}
//     </div>
//   );
// }

//-----------------------------------------------

// import s from './styles.module.css'
// import {Name_groupe} from './components_part_mid/name_group/name_groupe'
// import {Chat} from './components_part_mid/chat/chat'
// export function Part_mid(currentGroup){

//     return (<div className={`${s.contain} col-9 col-xl-8`}>
        
//         {/* <Name_groupe currentGroup ={currentGroup}/> */}
        
//         <Chat currentGroup ={currentGroup}/>
//     </div>);
// }