import './global.css';
import { Part_left } from './components/part_left/part_left';
import { Part_mid } from './components/part_mid/part_mid';
import { Part_right } from './components/part_right/part_right';
import React from 'react';
import groups from './components/groups.json';  // Import JSON directly

function App() {
  // Initialize state with the first group by default
  const [currentGroup, setCurrentGroup] = React.useState(groups[0]);

  function updateCurrentGroup(group) {
    setCurrentGroup(group);
    console.log(group);
  }

  return (
    <div className='main'>
      <Part_left onItemClick={updateCurrentGroup} currentGroup={currentGroup} />
      <Part_mid currentGroup={currentGroup} />
      <Part_right currentGroup={currentGroup} />
    </div>
  );
}

export default App;
