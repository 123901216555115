import React from "react";
import s from "./styles.module.css";
import groups from "../../../groups.json";

export function Groups({ onClick, currentGroup }) {
  function renderGroups() {
    return groups.map((group) => {
      const isDisabled = group.index > 5;
      const isSelected = currentGroup && currentGroup.index === group.index;
      return (
        <div
          key={group.index}
          className={`${s.group} ${isSelected ? s.selectedGroup : ""} ${isDisabled ? s.disabledGroup : ""}`}
          onClick={() => !isDisabled && onClick(group)}
          style={{ 
            pointerEvents: isDisabled ? 'none' : 'auto',
            cursor: isDisabled ? 'not-allowed' : 'pointer'
          }}
        >
          <img
            src={group.imageUrl}
            alt={group.groupName}
            className={s.groupImage}
          />

          <div className={s.infoGrp}>
            <h3 className={s.titleGrp}>{group.groupName}</h3>
            <p className={`d-none d-xxl-block`}>
              {group.index > 5 ? `${group.unlock}` : `Messages: ${group.messages.length}`}
            </p>
          </div>
        </div>
      );
    });
  }

  return (
    <div className={s.messageContainer}>
      {renderGroups()}
    </div>
  );
}