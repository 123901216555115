import React, { useEffect, useRef, useState } from 'react';
import s from "./styles.module.css";

const soundFile = process.env.PUBLIC_URL + '/assets/song/music.mp3'; // Chemin mis à jour vers le fichier MP3

export function Chat({ currentGroup }) {
  const [audio, setAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const messageContainerRef = useRef(null);

  useEffect(() => {
    const inputElement = document.getElementById('customInput');

    if (inputElement) {
      const handleInput = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault(); // Empêche le comportement par défaut de l'input
          if (currentMessage.trim()) {
            currentGroup.messages.push([1, currentMessage.trim()]);
            setCurrentMessage("");
            scrollToBottom(); // Faire défiler vers le bas après l'ajout d'un nouveau message
          }
        } else {
          event.preventDefault(); // Empêche l'ajout de la lettre du clavier
          const words = ["woof", "waaf", "arf"];
          const randomWord = words[Math.floor(Math.random() * words.length)];
          const currentText = event.target.value.trim();
          setCurrentMessage(currentText ? `${currentText} ${randomWord}` : randomWord);
        }
      };

      inputElement.addEventListener('keydown', handleInput);

      // Fonction de nettoyage pour supprimer l'écouteur d'événements
      return () => {
        inputElement.removeEventListener('keydown', handleInput);
      };
    }
  }, [currentMessage, currentGroup.messages]);

  useEffect(() => {
    scrollToBottom();
  }, [currentGroup.messages]);

  useEffect(() => {
    const handleClick = () => {
      if (isPlaying && audio) {
        audio.pause();
        audio.currentTime = 0;
        setIsPlaying(false);
      }
    };

    document.addEventListener('click', handleClick);

    // Fonction de nettoyage pour supprimer l'écouteur d'événements
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isPlaying, audio]);

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  };

  const playSound = () => {
    if (isPlaying) {
      audio.pause();
      audio.currentTime = 0;
      setIsPlaying(false);
    } else {
      const newAudio = new Audio(soundFile);
      setAudio(newAudio);
      newAudio.play().then(() => {
        setIsPlaying(true);
        newAudio.onended = () => setIsPlaying(false); // Réinitialise l'état lorsque l'audio se termine
      }).catch(error => {
        console.error("Error playing audio:", error);
      });
    }
  };

  function sendMessages() {
    return currentGroup.messages.map((message, index) => {
      if (message[1].includes(".mp3")) {
        return (
          <div key={index} className={s.audioMessage}>
            <audio>
              <source src={message[1]} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        );
      } else if (message[1].includes("/")) {
        if (message[1].includes("msg_voc")) {
          return (
            <div key={index} className={s.imageMessage}>
              <img src={currentGroup.imageUrl} alt={currentGroup.groupName} className={s.groupImageSound} />
              <img className={s.imgSound} src={message[1].replace("/", "")} alt="message content" onClick={playSound} />
            </div>
          );
        
        } 
        else if(message[1].includes("video")){
          return (
            <div key={index} className={s.imageMessage}>
            <img src={currentGroup.imageUrl} alt={currentGroup.groupName} className={s.groupImage} />
            <video className={s.videoMessage} controls>
                <source src={message[1].replace("/", "")} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
          );
        }
        else if (message[1].includes("https:")){
          return (
            <div key={index} className={s.imageMessage}>
              <img src={currentGroup.imageUrl} alt={currentGroup.groupName} className={s.groupImage} />
              <a href="https://x.com/Teledoge"className={s.messageUnique} target="_blank">  {message[1]}</a>
            </div>
          );
        }
        else{return (
          <div key={index} className={s.imageMessage}>
            <img src={currentGroup.imageUrl} alt={currentGroup.groupName} className={s.groupImageImg} />
            <img className={s.imgChat} src={message[1].replace("/", "")} alt="message content" />
          </div>
        );}
      } else {
        return (
          <div
            key={index}
            className={message[0] === 1 ? s.myMessage : s.message}
            style={{ backgroundColor: message[0] === 1 ? 'azure' : 'transparent' }}
          >
            {message[0] === 1 || (
              <img src={currentGroup.imageUrl} alt={currentGroup.groupName} className={s.groupImage} />
            )}
            <div className={message[0] === 1 ? s.myMessageUnique : s.messageUnique}>
              <p>{message[1]}</p>
            </div>
          </div>
        );
      }
    });
  }

  return (
    <div className={s.countain}>
      <div className={s.messageContainer} ref={messageContainerRef}>
        <div className={s.triche}></div>
        {sendMessages()}
      </div>
      <input
        type="text"
        id="customInput"
        placeholder="Type a message"
        className={s.input}
        value={currentMessage}
        onChange={(e) => setCurrentMessage(e.target.value)}
      />
    </div>
  );
}