import React from 'react';
import s from './styles.module.css';

export function Name_groupe( { currentGroup }) { // Destructure to get the nested currentGroup


    return (
        <div className={s.countain}>
            <img src={currentGroup.imageUrl} alt={currentGroup.groupName} className={s.groupImage} />
            <div className={s.txt}>
           <h3>{currentGroup.groupName}</h3>
           <p className={s.members}>{currentGroup.members[0] <= 1? currentGroup.members[0] +" member, ":currentGroup.members[0]+" members, "}  {currentGroup.members[1]}  Online</p>
           </div>
        </div>
    );
}
