import s from "./styles.module.css";

export function Elements({ currentGroup }) {
  return (
    <div className={s.countain}>
      <div className={s.elmt}>
      <span className={`${s.icon} material-symbols-outlined`}>image</span>
        <p className={s.number}>{currentGroup.Images}&nbsp;</p>
        <p className={s.titler}>Photos</p>
      </div>
      <div className={s.elmt}>
      <span className={`${s.icon} material-symbols-outlined`}>videocam</span>
        <p className={s.number}>{currentGroup.Videos}&nbsp;</p>
        <p className={s.titler}>Videos</p>
      </div>
      <div className={s.elmt}>
      <span className={`${s.icon} material-symbols-outlined`}>folder</span>
        <p className={s.number}>{currentGroup.Files}&nbsp;</p>
        <p className={s.titler}>Files</p>
      </div>
      <div className={s.elmt}>
      <span className={`${s.icon} material-symbols-outlined`}>audio_file</span>
        <p className={s.number}>{currentGroup["Audio Files"]}&nbsp;</p>
        <p className={s.titler}>Audio Files</p>
      </div>
      <div className={s.elmt}>
      <span className={`${s.icon} material-symbols-outlined`}>link</span>
        <p className={s.number}>{currentGroup["Shared link"]}&nbsp;</p>
        <p className={s.titler}>Shared Link</p>
      </div>
      <div className={s.elmt}>
        <span className={`${s.icon} material-symbols-outlined`}>mic</span>
        <p className={s.number}>{currentGroup["Voices Messages"]}&nbsp;</p>
        <p className={s.titler}>Voices Messages</p>
      </div>
      <div className={s.elmt}>
      <span className={`${s.icon} material-symbols-outlined`}>gif_box</span>
        <p className={s.number}>{currentGroup.Gifs}&nbsp;</p>
        <p className={s.titler}>Gifs</p>
      </div>
    </div>
  );
}
