import s from './styles.module.css';
import { Channel_info } from './components_part_right/channel_info/channel_info';
import { Elements } from './components_part_right/elements/elements';
import { Leave_channel } from './components_part_right/leave_channel/leave_channel';


export function Part_right({currentGroup}) {
    return (<div className={`${s.contain} d-none d-xl-block col-0 col-xl-2`}>
        <Channel_info currentGroup={currentGroup} />
        <Elements currentGroup={currentGroup} />
        <Leave_channel currentGroup={currentGroup} />
    </div>);
}